<template>
	<main class="main">
		<section class="container" v-if="blocks">
      <Crumbs :data="crumbs"></Crumbs>

			<TopBlock :data="{ ...blocks[0], ...images }"></TopBlock>
		</section>

		<section class="main__box bg--gray" v-if="blocks">
			<div class="container">
				<AdvantagesBox
					:data="{ first: blocks[1], second: blocks[2], third: blocks[3] }"
				></AdvantagesBox>
			</div>
		</section>

		<div class="last">
			<section class="main__box main__box--big main__box--bg-circles">
				<div class="container">
					<h2 class="main__title main__title--center">
						Хотите присоединиться?
					</h2>
					<div class="flex flex--center">
						<router-link class="button" :to="{ name: 'JoiningProcedure' }"
							>Порядок присоединения</router-link
						>
					</div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import TopBlock from '@/components/TopBlock.vue'
import AdvantagesBox from '@/components/PartnerAdvantages.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'Advantages',

	components: {
		TopBlock,
		AdvantagesBox,
		Crumbs,
	},

	data() {
		return {
			crumbs: null,
			images: {
				animationBg: require('@/assets/images/background/bg_top-block-partner.svg'),
				animationFront: require('@/assets/images/icons/icon_partner-advantages.svg'),
				animationClassBg: 'animation-pattern',
				animationClassFront: 'animation-icon',
				animationClassBox: 'main__animation-box--partner',
			},
		}
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})

    this.crumbs = [
      {
        link: 'PartnerHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Преимущества',
      },
    ]
	},
}
</script>
